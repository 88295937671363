import Button from '@a3/chameleon/src/components/basic/Button';
import Typograf from '@a3/chameleon/src/components/basic/Typograf';
import { Caption1, H2 } from '@a3/chameleon/src/components/basic/Typography';
import type { DivProps } from 'app.types';
import cn from 'classnames';
import useAnalytics from 'hooks/useAnalytics';
import type { FC } from 'react';

import { gtmEvents } from 'constants/gtmEvent';
import pagesUrls from 'constants/pagesUrls';

import s from './Page404.module.scss';

type Page404Types = DivProps;

const Page404Components: FC<Page404Types> = ({ className, ...restProps }) => {
  const { clickAnalytics } = useAnalytics();

  return (
    <Typograf>
      <div className={cn(s.wrapper, className)} {...restProps}>
        <H2 className={s.header}>Страница не найдена</H2>
        <div className={s.errorImage} />
        <Caption1 className={s.description}>
          К сожалению, страница не найдена. <br />
          Попробуйте начать с главной.
        </Caption1>
        <Button
          className={s.button}
          size="medium"
          href={pagesUrls.home}
          onClick={(): void => clickAnalytics(gtmEvents.clickGoMainFrom404)}
        >
          Перейти на главную
        </Button>
      </div>
    </Typograf>
  );
};

export default Page404Components;
