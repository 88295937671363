import type { FC } from 'react';

import Page from 'components/layouts/Page';

import Page404Components from '../components/pagesComponents/Page404';

const Page404: FC = () => {
  return (
    <Page fullHeight withoutFooter withoutHeader>
      <Page404Components />
    </Page>
  );
};

export default Page404;
